<template>
  <div class="content_box">
    <el-card shadow="hover">
      <el-row :gutter="10">
        <el-col :span="5">
          <el-input
            v-model="queryFilter.keyWorlds"
            placeholder="请输入订单相关信息进行筛选"
            style="width: 100%"
            clearable
          ></el-input>
        </el-col>
        <el-col :span="5">
          <el-select
            v-model="queryFilter.status"
            filterable
            @focus="onFocus"
            clearable
            placeholder="请输入订单类型"
            style="width: 100%"
          >
            <el-option
              v-for="item in orderStateOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="5">
          <el-select
            v-model="queryFilter.lettingStatus"
            filterable
            @focus="onFocus"
            clearable
            placeholder="请选择刻字类型"
            style="width: 100%"
          >
            <el-option
              v-for="item in lettingStateOpen"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="5">
          <el-button type="primary" @click="flashTable" size="medium"
            >查询订单</el-button
          >
        </el-col>
      </el-row>
    </el-card>
    <el-card style="margin-top: 20px">
      <div slot="header" class="clearfix">
        <span>销售订单列表</span>
      </div>
      <el-table
        :data="tableData"
        stripe
        style="width: 100%"
        :max-height="800"
        border
      >
        <el-table-column fixed="left" type="expand">
          <template slot-scope="scope">
            <el-row style="margin: 10px">
              <el-table
                :data="scope.row.keiziList"
                stripe
                style="width: 100%"
                :max-height="800"
                border
              >
                <el-table-column type="index" label="#"></el-table-column>
                <el-table-column
                  prop="orderNo"
                  label="关联订单号"
                ></el-table-column>
                <el-table-column
                  prop="keZi_UrlUploadTime"
                  label="刻字上传时间"
                ></el-table-column>
                <el-table-column label="刻字状态">
                  <template slot-scope="keziScope">
                    <div v-if="keziScope.row.keZi_Url">
                      <el-tag
                        effect="dark"
                        v-if="keziScope.row.keZi_Status == 0"
                        >待审核</el-tag
                      >
                      <el-tag
                        effect="dark"
                        type="warning"
                        v-else-if="keziScope.row.keZi_Status == 1"
                        >待刻字</el-tag
                      >
                      <el-tag
                        effect="dark"
                        type="success"
                        v-else-if="keziScope.row.keZi_Status == 2"
                        >已刻字</el-tag
                      >
                      <el-tag
                        effect="dark"
                        type="danger"
                        v-else-if="keziScope.row.keZi_Status == 3"
                        >未通过</el-tag
                      >
                    </div>
                  </template>
                </el-table-column>

                <el-table-column prop="keZi_Type" label="刻字类型">
                </el-table-column>

                <el-table-column
                  prop="confirmRemark"
                  label="刻字备注"
                ></el-table-column>

                <el-table-column label="刻字文件地址">
                  <template slot-scope="keziScope">
                    <div v-if="keziScope.row.keZi_Url">
                      <!-- 
                      <template v-if="keziScope.row.keZi_Type == 1">
                        <el-link
                          :underline="false"
                          type="primary"
                          icon="el-icon-download"
                          @click.stop="
                            downloadExcelFiel(
                              keziScope.row.keZi_Url,
                              keziScope.row.orderNo
                            )
                          "
                          >文件下载</el-link
                        >&nbsp;
                        <el-link
                          :underline="false"
                          icon="el-icon-view"
                          type="info"
                          target="_blank"
                          :href="
                            'https://view.officeapps.live.com/op/view.aspx?src=' +
                            encodeURI(keziScope.row.keZi_Url)
                          "
                          >在线预览</el-link
                        >
                      </template>
                      -->
                      <!--
                      <template v-else>
                        -->
                      <el-link
                        :underline="false"
                        type="primary"
                        icon="el-icon-download"
                        @click.stop="downloadExcelFile2(keziScope.row)"
                        >文件下载</el-link
                      >&nbsp;
                      <el-link
                        :underline="false"
                        icon="el-icon-view"
                        type="info"
                        target="_blank"
                        @click.stop="openExcelFile(keziScope.row)"
                        >在线预览</el-link
                      >
                      <!-- </template> -->
                    </div>
                  </template>
                </el-table-column>

                <el-table-column fixed="right" width="200" label="操作">
                  <template
                    slot-scope="keziScope"
                    v-if="keziScope.row.keZi_Url"
                  >
                    <el-button
                      type="primary"
                      size="mini"
                      :disabled="
                        keziScope.keZi_Status != 1 && keziScope.keZi_Status == 2
                      "
                      @click="
                        openAddLetteringDialog(keziScope.row, keziScope.row.id)
                      "
                    >
                      提交刻字订单
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-row>
          </template>
        </el-table-column>

        <el-table-column fixed="left" type="index" label="#"> </el-table-column>

        <el-table-column fixed="left" width="200" label="订单编号">
          <template slot-scope="scope">
            <el-link
              :underline="false"
              @click="handleCopy(scope.row.orderNo, $event)"
              >{{ scope.row.orderNo }}</el-link
            >
          </template>
        </el-table-column>
        <el-table-column prop="tradeType" width="100" label="交易类型">
        </el-table-column>
        <el-table-column prop="status" width="100" label="订单状态">
          <template slot-scope="scope">
            <el-tag :type="tagType(scope.row.status)" effect="dark">
              {{ scope.row.status }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="status" width="200" label="订单创建时间">
          <template slot-scope="scope">
            {{ scope.row.createTime }}
          </template>
        </el-table-column>

        <el-table-column prop="receiverName" width="100" label="收货人姓名">
        </el-table-column>
        <el-table-column prop="receiverPhone" width="180" label="收货人电话">
        </el-table-column>
        <el-table-column label="刻字状态">
          <template slot-scope="scope">
            <div v-if="scope.row.keZi_Url">
              <el-tag effect="dark" v-if="scope.row.keZi_Status == 0"
                >待审核</el-tag
              >
              <el-tag
                effect="dark"
                type="warning"
                v-else-if="scope.row.keZi_Status == 1"
                >待刻字</el-tag
              >
              <el-tag
                effect="dark"
                type="success"
                v-else-if="scope.row.keZi_Status == 2"
                >已刻字</el-tag
              >
              <el-tag
                effect="dark"
                type="danger"
                v-else-if="scope.row.keZi_Status == 3"
                >未通过</el-tag
              >
            </div>

            {{ letteringState(scope.row.keZi_Url) }}
          </template>
        </el-table-column>
        <el-table-column label="刻字文件地址">
          <template slot-scope="scope">
            <div v-if="scope.row.keZi_Url">
              <el-link
                :underline="false"
                type="primary"
                icon="el-icon-download"
                @click.stop="
                  downloadExcelFiel(scope.row.keZi_Url, scope.row.orderNo)
                "
                >文件下载</el-link
              >&nbsp;
              <el-link
                :underline="false"
                icon="el-icon-view"
                type="info"
                target="_blank"
                :href="
                  'https://view.officeapps.live.com/op/view.aspx?src=' +
                  encodeURI(scope.row.keZi_Url)
                "
                >在线预览</el-link
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column fixed="right" width="200" label="操作">
          <template slot-scope="scope" v-if="scope.row.keZi_Url">
            <el-button
              type="primary"
              size="mini"
              :disabled="scope.keZi_Status != 1 && scope.keZi_Status == 2"
              @click="openAddLetteringDialog(scope.row)"
            >
              提交刻字订单
            </el-button>
            <!--
            <el-button
              type="primary"
              icon="el-icon-edit"
              @click="pushOrderInfo(scope.row.orderNo)"
              circle
            ></el-button>
            <el-button
              type="success"
              icon="el-icon-check"
              @click="printRowData(scope.row)"
              circle
            ></el-button>
            <el-button
              type="info"
              icon="el-icon-message"
              @click="printRowData(scope.row)"
              circle
            ></el-button>
            -->
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 20px; text-align: right"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryFilter.pageIndex"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="queryFilter.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="queryFilter.total"
      >
      </el-pagination>

      <!-- 提交刻字订单 -->
      <el-dialog
        title="提交刻字订单信息"
        :visible.sync="addLetteringDialogVisabled"
        @close="addLetteringDialogClose"
      >
        <el-form :model="addLetteringForm" label-width="100">
          <el-form-item label="供应商名称">
            <el-select
              filterable
              @focus="onFocus"
              v-model="addLetteringForm.supplierId"
              placeholder="请选择供应商"
              @change="supplierSelectChange"
            >
              <el-option
                v-for="item in supplierOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注">
            <el-input v-model="addLetteringForm.remark" placeholder="备注" />
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="addLetteringDialogVisabled = false"
            >取 消</el-button
          >
          <el-button type="primary" @click="addLetteringComfirm"
            >确 定</el-button
          >
        </div>
      </el-dialog>
    </el-card>
  </div>
</template>
<script>
import axios from 'axios'
import clip from '@/utils/clipboard'
import { downloadFile, addLettering } from '@/api/lettering.js'
import {
  getOrder,
  getOrderStatusOptions,
  getKeziFile,
  getKeziFile2,
} from '@/api/order.js'
import { getAll } from '@/api/supplier.js'
import { iosOnFocus } from '@/utils/iosPlatform.js'
export default {
  data() {
    return {
      // 表格数据
      tableData: [],
      // 分页筛选数据
      queryFilter: {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
        keyWorlds: '',
        status: null,
        // 刻字状态
        lettingStatus: null,
      },
      lettingStateOpen: [
        {
          label: '无刻字',
          value: 1,
        },
        {
          label: '有刻字',
          value: 2,
        },
        {
          label: '待刻字',
          value: 3,
        },
        {
          label: '已刻字',
          value: 4,
        },
      ],
      orderStateOptions: [],
      supplierOptions: [],
      addLetteringDialogVisabled: false,
      addLetteringForm: {
        id: null,
        linkeOrderNumber: '',
        fileUrl: '',
        supplierId: null,
        supplierName: '',
        remark: '',
      },
    }
  },
  methods: {
    // 获取供应商选项数据
    async getSupplierOptions() {
      let { data } = await getAll()
      this.supplierOptions = data.map((item) => {
        return { id: item.id, name: item.name }
      })
    },
    // 获取销售订单信息
    async getSaleOrderDate() {
      let { data } = await getOrder(this.queryFilter)

      this.tableData = data.data

      this.queryFilter.total = data.total
    },
    flashTable() {
      this.queryFilter.pageIndex = 1
      this.getSaleOrderDate()
    },
    // 分页大小更改
    handleSizeChange(value) {
      this.queryFilter.pageSize = value
      this.getSaleOrderDate()
    },
    // 当前索引更改
    handleCurrentChange(val) {
      this.queryFilter.pageIndex = val
      this.getSaleOrderDate()
    },
    // 获取分页筛选条件
    async getOrderStateOptions() {
      let { data } = await getOrderStatusOptions()
      this.orderStateOptions = data
    },
    // 操作粘贴板使用
    handleCopy(text, event) {
      if (text !== '') {
        clip(text, event)
      }
    },
    // 跳转到详情
    pushOrderInfo(orderNumber) {
      this.$router.push({ path: '/Sale/orderInfo/' + orderNumber })
    },
    // 打印当前行数据
    printRowData(row) {
      console.log(row)
    },
    tagType(value) {
      if (value == '待发货' || value == '待对账') {
        return 'warning'
      }

      if (value == '待支付') {
        return 'info'
      }

      if (value == '交易关闭' || value == '退款中') {
        return 'danger'
      }

      if (value == '已发货' || value == '已签收' || value == '交易完成') {
        return 'success'
      }

      return 'info'
    },
    letteringState(val, id) {
      let showTxt = '待审核'
      let tagType = ''
      // if (val === 0) {
      //   showTxt = '待审核'
      // }
      if (val === 1) {
        showTxt = '待刻字'
        tagType = 'warning'
      }
      if (val === 2) {
        showTxt = '已刻字'
        tagType = 'success'
      }
      if (val === 3) {
        showTxt = '未通过'
        tagType = 'danger'
      }
    },
    async downloadExcelFiel(url, fileName) {
      await downloadFile(url, fileName)
    },
    async downloadExcelFile2(row) {
      if (row.keZi_Type == 1) {
        let { data } = await getKeziFile(row.id)
        await downloadFile(data, row.orderNo)
      } else {
        let { data } = await getKeziFile2(row.id)
        await downloadFile(data, row.orderNo)
      }
    },
    async openExcelFile(row) {
      if (row.keZi_Type == 1) {
        let { data } = await getKeziFile(row.id)
        window.open(
          'https://view.officeapps.live.com/op/view.aspx?src=' +
            encodeURI(data),
          '_blank'
        )
      } else {
        let { data } = await getKeziFile2(row.id)
        window.open(
          'https://view.officeapps.live.com/op/view.aspx?src=' +
            encodeURI(data),
          '_blank'
        )
      }
    },
    async openAddLetteringDialog(row, id = -1) {
      this.addLetteringForm.id = id
      this.addLetteringForm.linkeOrderNumber = row.orderNo

      if (row.keZi_Type == 1) {
        let { data } = await getKeziFile(row.id)
        this.addLetteringForm.fileUrl = data
      } else {
        let { data } = await getKeziFile2(row.id)
        this.addLetteringForm.fileUrl = data
      }

      this.addLetteringDialogVisabled = true
    },
    addLetteringDialogClose() {
      this.addLetteringForm = {
        id: null,
        linkeOrderNumber: '',
        fileUrl: '',
        supplierId: null,
        supplierName: '',
        remark: '',
      }
    },
    supplierSelectChange(val) {
      this.addLetteringForm.supplierName = this.supplierOptions.filter(
        (item) => {
          return item.id == val
        }
      )[0].name
    },
    async addLetteringComfirm() {
      if (!this.addLetteringForm.supplierId) {
        return this.$message.error('供货商信息为必选项目')
      }
      await addLettering(this.addLetteringForm)

      this.$message.success('刻字订单新增成功')
      this.addLetteringDialogVisabled = false
    },
    onFocus(event) {
      iosOnFocus.call(this,event);
    },
  },
  created() {
    this.getSaleOrderDate()
    this.getOrderStateOptions()
    this.getSupplierOptions()
  },
}
</script>
<style lang="scss" scoped>
.content_box {
  width: 100%;
  height: 100%;
  // background-color: white;
}
.el-select {
  width: 100%;
}
</style>
